import  "./datepicker/datepicker-full";
Datepicker.locales.th = {
    days: ["อาทิตย์", "จันทร์", "อังคาร", "พุธ", "พฤหัส", "ศุกร์", "เสาร์", "อาทิตย์"],
    daysShort: ["อา", "จ", "อ", "พ", "พฤ", "ศ", "ส", "อา"],
    daysMin: ["อา", "จ", "อ", "พ", "พฤ", "ศ", "ส", "อา"],
    months: ["มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"],
    monthsShort: ["ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.", "พ.ค.", "มิ.ย.", "ก.ค.", "ส.ค.", "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค."],
    today: "วันนี้",
    titleFormat: {
        toDisplay(date) {
            return new Intl.DateTimeFormat("th-TH", { month: "long", year: "numeric" }).format(date);
        }
    }
};

document.addEventListener("htmx:afterOnLoad", function(e) {
    initAllDatPicker();
});

function initAllDatPicker() {
    for (const elem of document.querySelectorAll("input[data-date]")) {
        console.log(elem.datepicker)
        let minDate = elem.getAttribute('data-date-min');
        let maxDate = elem.getAttribute('data-date-max');

        let datepicker = new Datepicker(elem, {
            buttonClass: "btn",
            language: "th",
            autohide: true,
            minDate:new Date(minDate),
            todayButton:false,
            clearButton:true,
            todayHighlight:true,
            format: {
                toValue(dateStr, format, locale) {
                    if (dateStr && dateStr.length) {
                        var parts = dateStr.split("/");
                        let year = parseInt(parts[2], 10) - 543
                        let month = parseInt(parts[1], 10) - 1
                        let date =  parseInt(parts[0], 10)
                        return new Date(year, month, date);
                    } else {
                        console.warn("can't parse date");
                        return new Date();
                    }
                },
                toDisplay(date, format, locale) {
                    console.log(date)
                    let month = '' + (date.getMonth() + 1)
                    let day = '' + date.getDate()
                    let year = date.getFullYear()

                    if (month.length < 2)
                        month = '0' + month;
                    if (day.length < 2)
                        day = '0' + day;

                    console.log(date)
                    return [ day,month,year+543].join('/');
                    //return new Intl.DateTimeFormat("th-TH").format(date);
                }
            },
            beforeShowYear(date) {
                return {content: `<button type="button" class="btn view-switch" tabindex="-1">${date.getFullYear()+543}</button>`};
            },
            beforeShowDecade(date) {
                return {content:`${date.getFullYear() + 543}`}
            }
        });
    }
}
initAllDatPicker();