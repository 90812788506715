document.body.addEventListener('htmx:sendError', function (e) {
  e.preventDefault();
  Alpine.store('toast', {
    open: true,
    title: 'เกิดข้อผิดพลาด',
    message: `ไม่สามารถเชื่อมต่อเซอร์เวอร์ได้`,
    type:'WARN'
  });
  setTimeout(()=>{
    Alpine.store('toast', {
      open: false
    });
  },6000)
});

document.body.addEventListener('htmx:responseError', function (e) {
  e.preventDefault();
  if (event.detail.xhr.status === 500){
    Alpine.store('toast', {
      open: true,
      title: 'แจ้งเตือน',
      message: 'เกิดข้อผิดพลาด',
      type:'ERROR'
    });
    setTimeout(()=>{
      Alpine.store('toast', {
        open: false
      });
    },6000)
  }
});