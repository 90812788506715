import Alpine from "alpinejs";
// import dropDownProvince from "./province-dropdown";
import "idiomorph";
import * as bootstrap from "bootstrap";
import htmx from "htmx.org/dist/htmx.esm";
import "./json-enc-custom";
import "./vanillajs-init"
import "./menu"

window.htmx = htmx;
window.Alpine = Alpine;
console.log("active url", window.location.pathname);

Alpine.data("sidebarmenu", (initActiveEl = window.location.pathname) => ({
  activeEl: initActiveEl,
  openItems: {
    "consideration": true,
    "request": true,
    "eligible": true,
    "report": true,
    "config": true,
    "e-form": true,
    "test": true
  },
  toggle(item) {
    if (this.openItems[item] !== undefined) {
      this.openItems[item] = !this.openItems[item];
    } else {
      this.openItems[item] = true;
    }
  }
}));
// Alpine.data("province", (id) => dropDownProvince(id));

Alpine.store("toast", {
  open: false
});
Alpine.store("confirmDialog", {
  open: false
});

Alpine.start();

window.closeModal = function(event) {
  const modal = bootstrap.Modal.getInstance(document.getElementById("gt-modal"));
  if (modal) {
    modal.hide();
  } else {
    console.error("Modal not found or not initialized");
  }
};

/*window.document.addEventListener("success", handleEvent);
window.document.addEventListener("error", handleEvent);
window.document.addEventListener("info", handleEvent);
window.document.addEventListener("warn", handleEvent);*/

document.body.addEventListener('htmx:responseError', function(e) {
  e.preventDefault();
  if (event.detail.xhr.status === 499) {
    window.location.reload()
  }
});

function base64DecodeUnicode(str) {
  let binaryString = atob(str);
  let bytes = new Uint8Array(binaryString.length);
  for (let i = 0; i < binaryString.length; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  let decodedText = new TextDecoder("utf-16le").decode(bytes);

  return decodeURIComponent(decodedText);
}

window.document.body.addEventListener("success", e => {
  if (e.detail.dialog) {
    const modal = bootstrap.Modal.getInstance(document.querySelector(e.detail.dialog.selector));
    if (modal) {
      if (e.detail.dialog.action === "HIDE") {
        modal.hide();
      } else {
        console.error('not support');
      }
    } else {
      console.warn('Modal not found or not initialized');
    }
  }
  if (e.detail.toast) {
    Alpine.store('toast', {
      open: true,
      title: base64DecodeUnicode(e.detail.toast.title),
      message: base64DecodeUnicode(e.detail.toast.message),
      type: e.detail.toast.type
    });
    setTimeout(function () {
      Alpine.store('toast', {
        open: false,
        title: '',
        message: '',
        type: ''
      });
    }, 5000)
  }
})

window.document.addEventListener('htmx:confirm', function (e) {
  // htmx always call fired confirm event if do not have attr hx-confirm see https://github.com/bigskysoftware/htmx/issues/2029
  // so we don't care if question empty
  if (!e.detail.question) {
    return
  }
  if (e.target.classList.contains("showConfirm")) {
    e.preventDefault();
    const confirmModal = new bootstrap.Modal(document.getElementById('confirmModal'));
    const confirmModalBody = document.getElementById('confirmModalBody');
    const confirmBtn = document.getElementById('confirmBtn');

    // Set confirmation message
    confirmModalBody.textContent = e.detail.question;

    // Show modal
    confirmModal.show();

    confirmBtn.onclick = function () {
      e.detail.issueRequest(true)
      confirmModal.hide();
    };
  }

  if (e.target.classList.contains("showWarning")) {
    e.preventDefault();
    let elementById = document.getElementById('warningModal');
    const confirmModal = new bootstrap.Modal(elementById);
    const confirmModalBody = elementById.querySelector("#confirmModalBody");
    const closeBtn = elementById.querySelector("#closeButton")

    // Set confirmation message
    confirmModalBody.textContent = e.detail.question;

    // Show modal
    confirmModal.show();
    e.detail.issueRequest(true)
    setTimeout(function () {
      confirmModal.hide();
    }, 5000);
  }
})
