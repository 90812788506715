let currentUrl = window.location.pathname
const root = document.getElementById("sidebarmenu")
if (root) {
    let menus = root.querySelectorAll("a[hx-get]");
    let activeElm = root.querySelector("a[hx-get].active");
    changeActiveMenu(); //for refresh button/ or first load
    document.addEventListener("htmx:beforeSend", function (e) {
        console.log(currentUrl)
        currentUrl = window.location.pathname
        activeElm = root.querySelector("a[hx-get].active");
    })

    function changeActiveMenu() {
        for (const menuItemElm of menus) {
            const hxUrl = menuItemElm.getAttribute("hx-get")
            console.log(hxUrl, window.location.pathname.startsWith(hxUrl))
            if (window.location.pathname.startsWith(hxUrl)) {
                activeElm?.classList.remove("active")
                menuItemElm.classList.add("active")
                activeElm = menuItemElm
            }
        }
    }

    document.addEventListener("htmx:afterSettle", function (e) {
        changeActiveMenu();
    });
}